<template>
  <div class="ui-burger-menu" data-testid="element_000328">
    <span data-testid="element_000049" />
    <span data-testid="element_000050" />
    <span data-testid="element_000051" />
    <span data-testid="element_000052" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ComponentBurgerMenu',
})
</script>

<style lang="scss">
.ui-burger-menu {
  width: 18rem;
  height: 18rem;
  margin: 8rem 22rem 8rem 8rem;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  &:active {
    span {
      background: map_get($colorPalette, "color_09");
    }
  }

  span {
    display: block;
    position: absolute;
    height: 2rem;
    width: 100%;
    background: map_get($grayPalette, "color_ff");
    border-radius: 9rem;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  & span:nth-child(1) {
    top: 4rem;
  }

  & span:nth-child(2),
  & span:nth-child(3) {
    top: 8rem;
  }

  & span:nth-child(4) {
    top: 12rem;
  }

  &.open span:nth-child(1) {
    top: 4rem;
    width: 0%;
    left: 50%;
  }

  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: 8rem;
    width: 0%;
    left: 50%;
  }
}
</style>
