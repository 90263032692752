<template>
  <header id="header" class="header" data-testid="element_000232">
    <div class="left" data-testid="element_000233">
      <div class="logo-container" data-testid="element_000234">
        <burger-menu v-if="!isContentWithHeader" :class="menuBurgerClasses" @click.stop="changeVisibleLeftMenu(!visibleLeftMenu)" />
        <logo @click.stop="changeVisibleMobileSearch(false)" />
      </div>
      <client-only v-if="isShowSearch">
        <search v-if="width > 1199" id="header-search" class="header-search" :floating-catalog="true" />
      </client-only>
    </div>
    <div v-if="!isContentWithHeader" class="right" data-testid="element_000235">
      <div
        v-if="userStore.isAuthorized"
        class="coin icon-btn"
        data-testid="element_000236"
        @click="router.push({ name: 'buy-tokens' })"
      >
        <span>{{ balance }}</span>
        <ui-icon :size="18" name="Filled/Token" filled />
      </div>

      <div :class="searchBtnClasses" data-testid="element_000237" @click.stop="searchBtnOnClick">
        <svg
          width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <use href="/svg/search.svg#search" />
        </svg>
      </div>

      <div v-if="userStore.isAuthorized" class="icon-btn user-btn">
        <bell-notifs />
      </div>

      <nuxt-link v-if="userStore.isAuthorized" :to="{ name: 'account-library' }" class="icon-btn user-btn">
        <svg
          width="18" height="18" viewBox="0 0 18 18" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href="/svg/heart.svg#heart" />
        </svg>
      </nuxt-link>

      <div class="icon-btn user-btn" data-testid="element_000240" @click="userBtnAction">
        <div
          class="user-popup"
          data-testid="element_000241"
        >
          <svg
            width="18" height="18" viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use href="/svg/user.svg#user" />
          </svg>
        </div>
      </div>

      <button-component tag="NuxtLink" :to="{ name: userStore.isAuthorized && userStore.isStatus.active === true ? 'buy-tokens' : 'premiumlp' }" class="header-rigth-btn btn-main button button_primary icon-btn">
        {{ btnText }}
      </button-component>
    </div>
    <div v-if="isContentWithHeader && userStore.isAuthorized" class="right" data-testid="element_000235">
      <button-component @click="userStore.logout()" class="header-rigth-btn btn-main button button_second icon-btn">
        Log Out
      </button-component>
    </div>
  </header>
</template>

<script lang="ts" setup>
import Search from '~/components/ui/search/search.vue'
import ButtonComponent from '~/components/ui/button/button.vue'
import BellNotifs from '~/components/ui/bell-notifs/bell-notifs.vue'
import Logo from '~/components/ui/logo/logo.vue'
import BurgerMenu from '~/components/ui/burger-menu/burger-menu.vue'
import TokenIcon from '~/components/svg/token.vue'
import { useDreamcamAuthStore } from "~/stores/dreamcam/auth"

interface Props {
  isContentWithHeader?: boolean
  isShowSearch?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isContentWithHeader: false,
  isShowSearch: true,
})

const { isContentWithHeader, isShowSearch } = toRefs(props)

const router = useRouter()
const userStore = useUserStore()
const { width } = useSSRWindowSize()


const {
  visibleMobileSearch,
  visibleUserPopup,
  visibleLeftMenu,
  changeVisibleMobileSearch,
  changeVisibleLeftMenu,
  changeVisibleUserPopup,
} = useLayoutDefault()

const userBtnAction = () => {
  if (userStore.isAuthorized) {
    return changeVisibleUserPopup(!visibleUserPopup.value)
  }
  return router.push({ name: 'login' })
}

const btnText = computed(() => {
  if (!userStore.isAuthorized) {
    return 'Join Now'
  } else if (userStore.isAuthorized && userStore.isStatus.active === true) {
    return 'BUY TOKENS'
  }
  return 'GET PREMIUM'
  // if premium 'BUY TOKENS'
})

const searchBtnClasses = computed(() => {
  return [
    "icon-btn",
    "search-btn",
    visibleMobileSearch.value ? "search-btn__active" : "",
  ]
})

const menuBurgerClasses = computed(() => {
  return [
    "mr2-7 hamburger-menu",
    visibleLeftMenu.value ? "open" : "",
  ]
})

async function searchBtnOnClick() {
  await nextTick(() => {
    changeVisibleMobileSearch(!visibleMobileSearch.value)
  })
}

const dreamcamAuthStore = useDreamcamAuthStore()

const balance = computed(() => {
  return useShortNumber(dreamcamAuthStore.balance)
})

onMounted(() => {
  dreamcamAuthStore.getBalance()
})
</script>
