<template>
  <NuxtLink :to="{ name: 'account-notification' }" class="ui-bell-notify icon-btn" data-testid="element_000044">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <use href="/svg/bell.svg#bell" />
    </svg>
    <div :key="`${isUnreadBell}`" :class="classesSphere" data-testid="element_000327">
      <div class="ui-bell-notify__sphere-dot" data-testid="elem_vrp_000821" />
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useNotificationsStore } from '@/stores/notifications'

const notificationsStore = useNotificationsStore()

const isUnreadBell = computed(() => notificationsStore.isUnreadBell)

const classesSphere = computed(() => {
  return  {
    'ui-bell-notify__sphere': true,
    'ui-bell-notify__sphere--have-unread': isUnreadBell.value
  }
})
</script>

<style lang="scss">
.ui-bell-notify {
  position: relative;

  & &__sphere {
    display: none;
    position: absolute;
    width: 18rem;
    height: 18rem;
    transform: translate(0%, 0%);
  }

  & &__sphere--have-unread {
    display: block;
  }

  &__sphere-dot {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 5rem;
    min-height: 5rem;
    background: map-get($colorPalette, 'color_81c');
    border: 2rem solid map-get($grayPalette, 'color_12');
    border-radius: 100%;
    box-sizing: content-box;
  }
}
</style>
